<template>
    <div class="d-flex overflow-hidden justify-content-center">
        <div
            class="cover-container position-relative w-100 gap-2 my-auto d-flex flex-column justify-content-center align-items-center d-none d-md-block">
            <div class="left-pane">
                <img src="../../assets/logo-2024-2.png" aria-hidden="true" alt="logo" />
                <div class="footnote">
                    <h6 class="mb-0"><b>Celebrating $50 million USD claimed! 🎉</b></h6>
                    <p class="mb-0">
                        <a href="https://www.creatorshield.com/results" target="_blank">Click here</a> to see our new
                        case studies page
                    </p>
                </div>
            </div>
        </div>
        <div class="position-relative w-100 gap-2 my-auto d-flex flex-column justify-content-center align-items-center"
            style="height: 100vh;">
            <div>
                <img class="d-flex m-auto" style="width: 60%;" src="../../assets/cs-logo.png" alt="CS Logo">
            </div>
            <div id="login-body" v-if="isSignIn">
                <div class="px-md-5 px-0">
                    <h3 class="fw-bold text-center my-4 header" style="font-size: 20px;">Log in to Creator Shield</h3>
                    <div class="mb-3">
                        <MDBInput class="my-3" label="Email" type="email" v-model="email" required
                            @keypress.enter="login" />
                        <MDBInput label="Password" type="password" v-model="password" required
                            @keypress.enter="login" />
                    </div>
                    <div class="text-end" style="font-size: 12px; cursor: pointer;" @click="toggleForgotPassword">
                        <span class="text-black header">Forgot Password?</span>
                    </div>
                    <button @click="login" data-mdb-ripple-init type="submit" color="primary"
                        class="btn btn-block btn-primary text-white mt-4">Log
                        in</button>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-4">
                    <h6 class="m-0 me-1" style="font-size: 13px;">Don't have an account?</h6>
                    <a href="https://creatorshield.com/onboarding" target="_blank" class="fw-bold text-black header"
                        style="font-size: 13px;">Sign
                        up</a>
                </div>
                <p class="text-center mt-4" style="font-size: 14px;">By continuing to use our service, you agree to
                    our <a href="https://www.creatorshield.com/tos" target="_blank">Terms of
                        Service</a>
                    which may be periodically updated.</p>
            </div>
            <div v-if="isForgotPassword" class="px-5">
                <div v-if="isSent">
                    <h3 class="text-center fw-bold my-4 mb-2" style="font-size: 24px;">Email has been sent.</h3>
                    <h6 class="text-center text-black-50 mt-3" style="margin-bottom: 30px;">Please follow the steps
                        mentioned in the email to reset your
                        password.</h6>
                </div>
                <div v-else>
                    <h3 class="text-center fw-bold my-4 mb-2 header" style="font-size: 24px;">Forgot Password</h3>
                    <h6 class="text-center text-black-50 mt-3" style="margin-bottom: 40px;">We'll email you a link to
                        reset your password.</h6>
                    <div>
                        <MDBInput class="my-3" label="Email" type="email" v-model="emailForget"
                            @keypress.enter="forgotPassword" required />
                    </div>
                    <button @click="forgotPassword" data-mdb-ripple-init type="submit"
                        class="btn btn-block btn-primary text-white mt-4">Send</button>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-4">
                    <h6 class="m-0 me-1" style="font-size: 13px;">Back to</h6>
                    <span @click="toggleSignIn" target="_blank" class="fw-bold text-black"
                        style="cursor: pointer;font-size: 13px;">Log in</span>
                </div>
            </div>
        </div>
    </div>
    <MDBToast v-model="toastError" id="basic-danger-example" autohide position="top-right" appendToBody stacking
        width="350px" color="danger" text="white" icon="fas fa-info-circle fa-lg me-2">
        <template #title>Error</template>
        {{ toastMessage }}
    </MDBToast>
</template>

<script setup>
import { MDBToast, MDBInput } from "mdb-vue-ui-kit";
import axios from "axios";
import { ref, onMounted } from "vue";
import { useTitle } from "@vueuse/core";
import { useRouter } from "vue-router";

const router = useRouter();

const email = ref("");
const password = ref("");
const toastError = ref(false);
const toastMessage = ref("");

onMounted(() => {
    useTitle("Login | CreatorShield");
});

const login = () => {
    axios
        .post("api/users/login", {
            email: email.value,
            password: password.value,
        })
        .then(function (response) {
            if (response.status === undefined) {
                if (response.response.status == 404) {
                    toastError.value = true;
                    toastMessage.value = "Incorrect email or password.";
                    password.value = "";
                } else {
                    toastError.value = true;
                    toastMessage.value = "An error occurred during request.";
                    password.value = "";
                }
            } else {
                router.push("/dashboard");
            }
        })
        .catch(function (error) {
            if (error.response.status == 404) {
                toastError.value = true;
                toastMessage.value = "Incorrect email or password.";
                password.value = "";
            } else {
                toastError.value = true;
                toastMessage.value = "An error occurred during request.";
                password.value = "";
            }
        });
};

const isSignIn = ref(true);
const isForgotPassword = ref(false);

const toggleForgotPassword = () => {
    isSent.value = false;
    isForgotPassword.value = true;
    isSignIn.value = false;
    useTitle("Forgot | CreatorShield");
};

const toggleSignIn = () => {
    isSignIn.value = true;
    isForgotPassword.value = false;
    useTitle("Login | CreatorShield");
};

const emailForget = ref("");
const isSent = ref(false);

const forgotPassword = () => {
    axios
        .post("api/users/forgotPassword", {
            email: emailForget.value,
        })
        .then(function (response) {
            if (response.status === undefined) {
                if (response.response.status == 404) {
                    toastError.value = true;
                    toastMessage.value = "Email does not exist.";
                    emailForget.value = "";
                } else {
                    toastError.value = true;
                    toastMessage.value = "An error occurred during request.";
                    emailForget.value = "";
                }
            } else {
                isSent.value = true;
            }
        })
        .catch(function (error) {
            if (error.response.status == 404) {
                toastError.value = true;
                toastMessage.value = "Email does not exist.";
                emailForget.value = "";
            } else {
                toastError.value = true;
                toastMessage.value = "An error occurred during request.";
                emailForget.value = "";
            }
        });
};
</script>

<style>
:root {
    --white: #ffffff;
    --primary: #fe632d;
    --secondary: #e2e6ef;
    --accent: #02061b;
    /* #252527 */
}

@font-face {
    font-family: 'GilroyRegular';
    src: url('../../assets/Gilroy-Font/Gilroy-Regular.ttf');
}

@font-face {
    font-family: 'GilroyBold';
    src: url('../../assets/Gilroy-Font/Gilroy-Bold.ttf');
}

* {
    font-family: 'GilroyRegular';
}

.header {
    font-family: 'GilroyBold';
}
</style>

<style scoped>
.btn-primary {
    background-color: var(--primary);
    font-family: 'GilroyBold';
}

.left-pane {
    height: 100vh;
    background-image: url("../../assets/you-create-we-protect.png"),
        linear-gradient(90deg, #fe632d, #f63939);
    background-size: cover, cover;
    background-position: center center;
    background-repeat: no-repeat, no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;

    @media screen and (max-width: 1199px) {
        background-position: center;
    }

    img[alt~="logo"] {
        width: 200px;
    }

    .footnote {
        color: white;

        a {
            color: white;
            text-decoration: underline;
            font-weight: bold;
        }
    }
}

#login-body {
    width: 70%;
    max-width: 400px;
}
</style>
